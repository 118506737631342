/**
*

    Template Name: Landi - Landscape Gardening React Template
    Author: TwinkleTheme
    Support: https://docs.twinkletheme.com/support/
    Description: Landi - Landscape Gardening React Template
    Version: 1.0

*/


/*

    CSS INDEX
    ===================

    01. Default
    02. Preloader
    03. Common Classes
    04. Section Title
    05. Header
    06. Hero area
    07. Service
    08. About
    09. Fun Fact
    10. Project
    11. Why Choose Us
    12. Testimonial
    13. Book Appointment
    14. How It Works
    15. Blog
    16. Footer
    17. Breadcrumb
    18. CTA
    19. Widgets
    20. FAQ
    21. Service/Project Details
    22. Pricing
    23. Error 404
    24. Blog Details
    25. Newsletter
    26. Features
    27. Team
    28. Quote Form
    29. Contact


-----------------------------------------------------------------------------------*/


/*----------------------------------------*/


/**
*   01. Default
*/

@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&family=Arial:wght@400;500;700&display=swap');
body {
    font-family: 'Arial';
    font-weight: 300;
    font-style: normal;
    color: #686868;
    font-size: 16px;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Arial';
    color: #4c9152;
    margin-top: 0px;
    font-weight: 700;
    text-transform: normal;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: #523906;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
    color: #523906;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 35px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

ul {
    margin: 0px;
    padding: 0px;
}

li {
    list-style: none;
}

p {
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    color: #5391b7;
    margin-bottom: 15px;
}
span{
    font-weight: 300;


}

input,
select {
    transition: 0.3s;
}

hr {
    border-bottom: 1px solid #eceff8;
    border-top: 0 none;
    margin: 30px 0;
    padding: 0;
}

label {
    color: #7e7e7e;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
}

.img,
img {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

a,
.button,
button {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

a:focus,
.button:focus {
    text-decoration: none;
    outline: none;
}

a:focus {
    text-decoration: none;
}

a:hover {
    color: #ec6e39;
}

a,
button {
    color: inherit;
    outline: medium none;
    text-decoration: none;
}

button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
    outline: 0;
}

*::-moz-selection {
    background: #222;
    color: #ffffff;
    text-shadow: none;
}

::-moz-selection {
    background: #222;
    color: #ffffff;
    text-shadow: none;
}

::selection {
    background: #222;
    color: #ffffff;
    text-shadow: none;
}

*::-moz-placeholder {
    color: #523906;
    font-size: 16px;
    opacity: .60;
}

*::placeholder {
    color: #523906;
    font-size: 16px;
    opacity: .60;
}

[data-overlay="theme-1"]::before {
    background-color: #523906;
}

[data-overlay="theme-2"]::before {
    background-color: #cdb30c;
}

[data-overlay="black"]::before {
    background-color: #000;
}
.scroll-to-top {
    position: fixed; /* Ensure the arrow stays in place while scrolling */
    bottom: 20px !important;
    right: 20px !important; /* Keep the arrow in a consistent location */
    width: 50px !important;
    height: 50px !important;
    line-height: 50px !important; /* Center the content vertically */
    font-size: 18px !important;
    text-align: center !important; /* Center the content horizontally */
    border-radius: 50% !important; /* Make the arrow circular */
    background-color: #5391b7;
    color: #ffffff;
    cursor: pointer; /* Add a pointer cursor to indicate it's clickable */
}

/* Assuming you are using a pseudo-element or a character for the arrow */
.scroll-to-top::before {
    content: "↑"; /* Up arrow character */
    display: block;
    font-size: 30px; /* Adjust size to your preference */

}


/**
*   02. Preloader
*/

.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #cdb30c;
    z-index: 99;
}

.preloader .sk-plane,
.preloader .sk-chase,
.preloader .sk-bounce,
.preloader .sk-wave,
.preloader .sk-pulse,
.preloader .sk-flow,
.preloader .sk-swing,
.preloader .sk-circle,
.preloader .sk-circle-fade,
.preloader .sk-grid,
.preloader .sk-fold,
.preloader .sk-wander {
    position: absolute;
    left: 50%;
    top: 45%;
    margin: 0;
    margin-left: -20px;
    margin-top: -20px;
}

.sk-plane,
.sk-chase-dot:before,
.sk-bounce-dot,
.sk-wave-rect,
.sk-pulse,
.sk-flow-dot,
.sk-swing-dot,
.sk-circle-dot:before,
.sk-circle-fade-dot:before,
.sk-grid-cube,
.sk-fold-cube:before,
.sk-wander-cube {
    background-color: #523906;
}


/**
*   03. Common Classes
*/

.fix {
    overflow: hidden;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.clear {
    clear: both;
}

.f-left {
    float: left;
}

.f-right {
    float: right;
}

.text-right {
    text-align: right;
}

.height-592 {
    height: 592px;
}

.height-800 {
    height: 800px;
}
.height-550 {
    height: 550px;
}
.height-470 {
    height: 470px;
}
.height-450 {
    height: 450px;
}
.height-500 {
    height: 500px;
}

.height-680 {
    height: 680px;
}
.height-700 {
    height: 850px;
}



.bg-property {
    background-color: #ddd;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-hover-style-1 {
    position: relative;
}

.bg-hover-style-1:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #523906;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.single-project:hover .bg-hover-style-1:before,
.works-content-wrapper:hover .bg-hover-style-1:before,
.single-blog-wrapper:hover .bg-hover-style-1:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #523906;
    position: absolute;
    opacity: .75;
    visibility: visible;
    transition: .3s;
}

.white-bg {
    background: #ffffff;
}

.gray-bg {
    background: #f1f1f1;
}

.gray-bg-2 {
    background: #f9f9f9;
}

.theme-bg-1 {
    background: #523906;
}

.theme-bg-2 {
    background: #3a2701;
}

.theme-bg-3 {
    background: #cdb30c;
}

.theme-bg-4 {
    background: #faf7e6;
}

.b-color-1 {
    border-color: #f2ecc2;
}

.b-color-2 {
    border-color: #523906;
}

.box-shadow-1 {
    box-shadow: 0px 3px 81px 0px rgba(6, 6, 6, 0.09);
}

.box-shadow-2 {
    box-shadow: 0px 3px 81px 0px rgb(6 6 6 / 9%);
}

.box-shadow-3 {
    box-shadow: 0px 3px 81px 0px rgba(0, 0, 0, 0.09);
}

.white-color {
    color: #ffffff;
}

.l-btn {
    background-color: #4c9152;
    color: #ffffff;
    font-family: 'Arial';
    font-size: 18px;
    font-weight: 700;
    padding: 22px 40px;
    border-radius: 50px;
    line-height: 1;
    display: inline-block;
    text-transform: uppercase;
    border: none;
   
}

.login-btn {
    background-color: #4c9152;
    color: #ffffff;
    font-family: 'Arial';
    font-size: 18px;
    font-weight: 700;
    padding: 15px 30px;
    border-radius: 50px;
    line-height: 1;
    display: inline-block;
    text-transform: uppercase;
    border: none;
    margin-top: 30%;
   
}

.login-btn:hover {
    background-color: #5391b7;
    color: #ffffff;
}


.l-btn:hover {
    background-color: #5391b7;
    color: #ffffff;
}


/**
*   04. Section Title
*/

.section-title span {
    display: block;
    font-size: 18px;
    color: #5391b7;
    line-height: 28px;
    margin-bottom: 30px;
}

.section-title h2 {
    font-size: 46px;
    line-height: 56px;
    font-weight: 100;
    color: #4c9152;
    
}

/*header*/
/* Styles for larger screens */
.header-bottom {
    display: flex;
    justify-content: center;
}

.header-container {
    width: 100%;
    max-width: 1200px;
    padding: 0 15px;
}

.row.d-flex.align-items-center {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo-container {
    display: flex;
    justify-content: flex-start;
    margin-left: -48px;
}

.nav-container {
    display: flex;
    justify-content: center;
}

.header-btn-container {
    display: flex;
    justify-content: flex-end;
}

.account-btn {
    display: flex;
    justify-content: center;
    background-color: #4c9152;
    color: #ffffff;
    font-family: 'Arial';
    font-size: 18px;
    font-weight: 700;
    padding: 22px 40px;
    border-radius: 50px;
    line-height: 1;
    display: inline-block;
    text-transform: uppercase;
    border: none;
}
.account-btn:hover{
    background-color: #5391b7;
    color: #ffffff;
}


/* Additional styles */
.header-top.pt-15.pb-15.theme-bg-1 {
    background-color: #8cb773;
}

.header-btn {
    position: relative;
    top: 10px;
    left: -20px;
    padding: 22px 0;
}

.header-info ul li {
    color: #5a4530;
    font-weight: 100;
    margin-right: 40px;
    display: inline-block;
}

.header-info ul li svg {
    color: #5a4530;
    font-weight: 100;
    margin-right: 10px;
    margin-top: -3px;
}

.header-social a {
    color: #5a4530;
    font-weight: 100;
    margin-left: 17px;
}

.header-info ul li a:hover,
.header-social a:hover {
    color: #f7eed7;
}

.header-call-btn {
    padding: 18px 0;
}

.main-menu ul li {
    display: inline-block;
    margin-left: 45px;
    position: relative;
}

.main-menu ul li a {
    color: #5391b7;
    font-size: 18px;
    font-weight: 100;
    display: block;
    padding: 10px 0;
}

.main-menu ul li:hover > a,
.main-menu ul li .submenu li:hover > a {
    color: #4c9152;
}

.main-menu ul:not(.submenu) > li:hover > a{
    border-bottom: 2px solid #4c9152 !important;
    transition: none;
}

.main-menu ul li .submenu {
    position: absolute;
    background-color: #ffffff;
    width: 230px;
    padding: 15px 25px;
    top: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 3;
}

.main-menu ul li:hover > .submenu {
    opacity: 1;
    visibility: visible;
}

.main-menu ul li .submenu li {
    display: block;
    margin-left: 0;
    text-align: left;
}

.main-menu ul li .submenu li a {
    display: block;
    color: #5391b7;
    padding: 8px 0;
}

.header-call-btn img {
    margin-right: 19px;
}

.header-call-btn a {
    font-size: 24px;
    font-weight: 100;
    line-height: 70px;
    color: #5906;
    font-family: 'Arial';
}

.header-call-btn a:hover {
    color: #4c9152;
}

.main-menu {
    margin-top: 1%;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
    .row.d-flex.align-items-center {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .logo-container,
    .header-btn-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .logo-container {
        width: auto;
        margin-left: 0;
    }

    /* Adjust logo size for mobile screens */
    .logo-container img {
        width: 70%; /* Adjust this value as needed for a smaller logo */
        height: auto;
    }

    .header-btn-container {
        width: auto;
        margin-left: 0%; /* Adjust spacing between the logo and button */
    }

    .mobile-menu {
        align-items: center;
        color: #5391b7;
        margin-right: 3%;
    }
    .mobile-menu:hover {
        align-items: center;
        color: #4c9152;
        margin-right: 3%;
    }
 


    .header-container {
        padding: 0 10px;
        max-width: 100%;
    }

    .nav-container {
        display: none; /* Hide main nav on mobile */
    }
    
    .mobile-menu-icon {
        font-size: 24px; /* Adjust the size of the mobile menu icon */
        cursor: pointer;
    }

    .account-btn {
        display: flex;
        justify-content: center;
        background-color: #4c9152;
        color: #ffffff;
        font-family: 'Arial';
        font-size: 15px;
        font-weight: 700;
        padding: 15px 20px;
        border-radius: 50px;
        line-height: 1;
        display: inline-block;
        text-transform: uppercase;
        border: none;
    }

    .account-btn:hover {
        background-color: #5391b7;
        color: #ffffff;
    }

    .header-btn {
        position: relative;
        left: -50px;
        padding: 22px 0;
        top: -2%;
    }
}
/*header*/
/* Base Styles for Hero Area */
/* Base Styles for Hero Area */
.hero-area {
    display: flex;
    align-items: center;
    height: 500px; /* Original height */
    background-color: #d3f3f1; 
}

/* Hero Wrapper Styles */
.hero-wrapper h1 {
    font-weight: 100;
    color: #4c9152;
    font-size: 50px; /* Default size for large screens */
}

.hero-title1 {
    font-size: 50px; 
    margin-right: 10px; /* Adjust spacing between titles if needed */
}

.hero-title2 {
    font-weight: 600;
    color: #5391b7;
    font-size: 50px; /* Default size for large screens */
}

.hero-title1, .hero-title2 {
    display: inline-block; /* Keep titles side by side */
}

.hero-title2 .hero-title3 {
    color: #4c9152;
    font-size: 50px; /* Default size for large screens */
}

.hero-subtitle1 {
    color: #5391b7;
    font-size: 30px; /* Default size for large screens */
}

.hero-subtitle2 {
    color: #5391b7;
    margin-top: 2%;
    font-size: 18px; /* Default size for large screens */
}

/* Link Button Styles */
.hero-btn {
    background-color: #4c9152;
    color: #ffffff;
    font-family: 'Arial';
    font-size: 18px;
    font-weight: 700;
    padding: 15px 30px;
    border-radius: 50px;
    line-height: 1;
    display: inline-block;
    text-transform: uppercase;
    border: none;
}

.hero-btn:hover {
    background-color: #5391b7;
    color: #ffffff;
}

/* Hero Popup Content */
.hero-popup-content {
    position: relative;
    padding: 20px;
    margin-left: 200px;
    margin-right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-popup-content img.leaf-thumbnail {
    width: 360px;
    height: 360px;
    border-radius: 70% 70% 70% 70%;
    border-width: 20px;
    border-style: solid;
    border-color: #5391b7;
    position: relative;
    z-index: 1;
    object-fit: cover;
}

.hero-popup-content:before {
    content: '';
    display: block;
    width: 420px;
    height: 420px;
    position: absolute;
    top: -20px;
    border-radius: 50%;
    border-width: 20px;
    border-style: solid;
    border-color: #4c9152;
    z-index: 0;
    transform: translate(2px, 10px);
}

@media only screen and (min-width: 1920px) {
    .hero-popup-content:before {
        left: -23px;
    }
}

/* Hero Popup Video */
.hero-popup-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.popup-video {
    background-color: #5391b7;
    border: none;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    padding: 0;
    cursor: pointer;
    color: #ffffff;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Pulse Effect */
.pulse-effect {
    animation: pulse-effect 1s infinite;
}

@keyframes pulse-effect {
    0% {
        box-shadow: 0 0 0 0px #fff;
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

/* Rotating Image */
.rotating-image {
    animation: rotate 10s linear infinite;
    width: 100%;
    height: auto;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* accepted recyclables */
.rotating-border {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 3px solid #4c9152;
    border-top: 3px solid #4c9152;
    border-right: 3px solid #4c9152;
    border-bottom: 3px solid #4c9152;
    border-left: 3px solid transparent;
    background: transparent;
    animation: rotate-border 3s linear infinite;
    z-index: 0;
}

@keyframes rotate-border {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/* AcceptedRecyclables.css */

.accepted-recyclables-container {
    position: relative;
    overflow: hidden;
    padding-top: 30px;
    margin-bottom: 30px;
}

.accepted-recyclables-title {
    text-align: center;
    margin-bottom: 30px;
}

.accepted-recyclables-title h2 {
    color: #4c9152;
}

.accepted-recyclables-item {
    text-align: center;
    padding: 20px;
}

.accepted-recyclables-item h3 {
    margin-bottom: 20px;
    font-size: 1.2em;
    white-space: nowrap; /* Prevent line break */
}

.icon-wrapper {
    position: relative;
    display: inline-block;
    margin-bottom: 30% !important;
}

.icon-container {
    color: #4c9152;
    font-size: 2.5em;
    margin-top: -70%;
    
 
}

.accepted-recyclables-grid {
    display: grid ;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px; /* Added gap to space out items */
}

@media (max-width: 768px) {

    .about-facility-container {
       height: 70%;
    }
    .accepted-recyclables-title h2 {
        color: #4c9152;
        font-size: 30px;
        font-weight: 300;
    }
    .accepted-recyclables-title p {
        font-size: 16px;   
        }

        .accepted-recyclables-grid {
            display: block ;
          
        }
        .accepted-recyclables-item h3 {
 
            font-size: 18px;
           
        }
        .accepted-recyclables-item p {
 
            font-size: 16px;
           
        }
        
    
    }
    



/* about facility sub */


.about-facility-container {
    position: relative;
    overflow: hidden;
    height: 450px;
    padding-top: 30px;
}


.about-facility-description {
    margin-top: 20px;
    display: block;
}

.about-facility-btn {
    background-color: #4c9152;
    color: #ffffff;
    font-family: 'Arial';
    font-size: 18px;
    font-weight: 700;
    padding: 15px 30px;
    border-radius: 50px;
    line-height: 1;
    display: inline-block;
    text-transform: uppercase;
    border: none;
}

.about-facility-btn:hover {
    background-color: #5391b7;
        color: #ffffff;}


@media (max-width: 768px) {

.about-facility-container {
   height: 70%;
}

}

/*hero*/
.hero-title-container {
    display: flex;
    flex-direction: row; /* Align items side-by-side */
    align-items: center;
}
/* Media Queries for Responsiveness */

@media (max-width: 1200px) {
    .hero-title1, .hero-title2, .hero-title3 {
        font-size: 40px; /* Size for tablets and small laptops */
    }

    .hero-subtitle1 {
        font-size: 24px; /* Size for tablets and small laptops */
    }

    .hero-subtitle2 {
        font-size: 16px; /* Size for tablets and small laptops */
    }
}

@media (max-width: 992px) {
    .hero-title1, .hero-title2, .hero-title3 {
        font-size: 36px; /* Size for medium screens */
    }

    .hero-subtitle1 {
        font-size: 20px; /* Size for medium screens */
    }

    .hero-subtitle2 {
        font-size: 14px; /* Size for medium screens */
    }
}

@media (max-width: 768px) {
    .hero-area {
        height: auto;
        display: block;
        padding: 20px 0; /* Add padding for better spacing */
    }

    .hero-title2-3 {
        margin-top: -10px; 
     
        /* Adjust spacing as needed */
    }

    .hero-title2 {
        font-weight: 600;
        color: #5391b7;
        font-size: 36px; /* Size for mobile screens */
    }
    
    .hero-title2 .hero-title3 {
        color: #4c9152;
        font-size: 36px; /* Ensure this matches hero-title2 */
    }
    
    .hero-subtitle1 {
        font-size: 18px; /* Size for mobile screens */
    }

    .hero-subtitle2 {
        font-size: 16px; /* Size for mobile screens */
    }

    .hero-popup-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0px;
    }

    .hero-popup-content img.leaf-thumbnail {
        width: 240px;
        height: 240px;
        border-width: 15px;
    }

    .hero-popup-content:before {
        content: '';
        display: block;
        width: 280px !important; /* Smaller size for mobile */
        height: 280px; /* Smaller size for mobile */
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        border-width: 15px;
        border-style: solid;
        border-color: #4c9152;
        z-index: 0;
        transform: translate(-50%, -50%); /* Center the circle */
    }

    .hero-popup-video {
        position: absolute;
        top: auto;
        left: auto;
        transform: none;
        margin-top: 15px;
    }

    .popup-video {
        background-color: #5391b7;
        border: none;
        border-radius: 50%;
        width: 60px; /* Adjusted size for mobile */
        height: 60px; /* Adjusted size for mobile */
        padding: 0;
        cursor: pointer;
        color: #ffffff;
        font-size: 26px; /* Adjusted size for mobile */
        
       
    }
    
    .hero-btn {
        display: flex;
        justify-content: center;
        background-color: #4c9152;
        color: #ffffff;
        font-family: 'Arial';
        font-size: 15px;
        font-weight: 700;
        padding: 15px 20px;
        border-radius: 50px;
        line-height: 1;
        display: inline-block;
        text-transform: uppercase;
        border: none;
        margin-bottom: 5%;
    }

    .hero-btn:hover {
        background-color: #5391b7;
        color: #ffffff;
    }
    .modal-video-inner{
        max-width: 960px;
        width: 100%;
        height: 100%;
        padding: 0;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
    }
    .modal-video-close-btn {
        position: absolute;
        z-index: 2;
        top: -45px;
        left: 85%;
        display: inline-block;
        width: 35px;
        height: 35px;
        overflow: hidden;
        border: none;
        background: transparent;
    }
    /* Ensure the text is centered and stacked vertically on mobile */

    .hero-title-container {
        flex-direction: column; /* Stack text vertically on mobile */
        align-items: flex-start; /* Align text to the left on mobile */
    
}
}

/*what is ola*/
.about-img-style-3 {
    display: flex; /* Use flexbox to center the content */
    justify-content: center; /* Centers the image horizontally */
    align-items: center; /* Centers the image vertically (if needed) */
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .what-is-ola-area {
        height: fit-content;
        padding-bottom: 10px; /* Adjusts the height of the area to fit content */
    }

    .about-img-style-3 {
        display: flex; /* Use flexbox to center the content */
        justify-content: center; /* Centers the image horizontally */
        align-items: center; /* Centers the image vertically (if needed) */
    }

    .about-img-style-3 img {
        max-width: 60%; /* Makes the image responsive and scales down proportionally */
        margin: 0; /* Removes margin auto if using flexbox */
    }
}

/*goal ola*/
@media (max-width: 768px) {
    .goal-ola-container {
        height: fit-content;
        padding-bottom: 10px; /* Adjusts the height of the area to fit content */
    }
}

/**
*   07. Service
*/
@media (max-width: 768px) {
.single-service2 h3{
   font-size: 18px;
} 

}
.single-service2{
    border: solid #5391b7;
    border-radius: 70px;
    padding: 20px;
    margin: 10px;
}
.single-service2:hover{
    background-color: #d3f3f1;
}
/* Row container using flexbox */
.row-services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -15px;
}

/* Column settings */
.service-column {
    padding: 15px;
    box-sizing: border-box;
}

/* Circular service box */
.single-service {
    background-color: #5391b7;
    width: 75%;
    height: 90%;
    border-radius: 50%;
    margin: 25px;
    transition: background-color 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    padding: 13%;
}

/* Service icon */
.service-icon {
    color: white;
    font-size: 40px; /* Adjust font size to be smaller */
    transition: opacity 0.3s;
}

/* Heading */
.service-heading {
    font-size: 20px; /* Smaller font size */
    color: white;
    margin: 10px 0;
    transition: opacity 0.3s;
}

/* Description settings (hidden by default) */
.service-description {
    opacity: 0;
    visibility: hidden;
    color: white;
    font-size: 17px; /* Smaller font size */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    transition: opacity 0.3s, visibility 0.3s;
}

/* Hover effect: Show description, hide icon and title */
.single-service:hover .service-icon,
.single-service:hover .service-heading {
    opacity: 0;
    visibility: hidden;
}

.single-service:hover .service-description {
    opacity: 1;
    visibility: visible;
}

/* Background color change on hover */
.single-service:hover {
    background-color: #4c9152;
}

/* About section text wrapper */
.about-text-wrapper {
    text-align: center;
    padding-top: 30px;
}

/* Service area container */
.service-area {
    height: fit-content;
    background-color: #d3f3f1; /* Background color */
    padding-bottom: 110px;
}


/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
    /* Stack columns on top of each other on mobile */
    .row-services {
        flex-direction: column;
        align-items: center;
    }

    .service-column {
        width: 90%; /* Make columns take full width */
        padding: 10px; /* Reduce padding */
    }

    .single-service {
        width: 100%; /* Make the circular service box full width */
        height: auto; /* Adjust height */
        border-radius: 70px; /* Change to rectangular on smaller screens */
        margin: 15px 0; /* Reduce margin */
        padding: 20px; /* Adjust padding */
    }

    .service-icon {
        font-size: 30px; /* Smaller icon size */
    }

    .service-heading {
        font-size: 18px; /* Smaller heading size */
    }

    .service-description {
        font-size: 16px; /* Smaller description size */
        position: static; /* Make text flow normally */
        transform: none; /* Remove transformation */
        text-align: center; /* Center align text */
        margin-top: 10px; /* Add margin to separate from heading */
        visibility: visible; /* Make description visible */
        opacity: 1; /* Fully visible */
        width: 100%; /* Full width */
    }

    .single-service:hover .service-icon,
    .single-service:hover .service-heading {
        opacity: 1; /* Keep icon and heading visible on hover */
        visibility: visible; /* Keep visibility */
    }

    .single-service:hover .service-description {
        opacity: 1; /* Keep description visible */
        visibility: visible; /* Keep visibility */
    }
    .service-area {
        background-color: #d3f3f1; /* Background color */
        height:auto;
        padding-bottom: 0;
    }
}

/*about facility*/

.bus-img {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically if needed */
    height: 100%; /* Ensure the container height allows centering */
}

.bus-img img {
    width: 700px; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
    border-radius: 70px;
    
}
/* Small screens and below */
@media (max-width: 768px) {
    .bus-img img {
        width: 100%; /* Full width for small screens */
        max-width: 400px; /* Limit maximum width */
        border-radius: 50px; 
        margin-top: -20%;/* Adjust border radius */
    }
   
}

/*history*/


.history-area {
    background-color: #d3f3f1;
  }
  
  .about-img-style-2 {
    position: relative;
    width: 400px;
    height: 400px;
    overflow: hidden;
    border-radius: 50%;
    padding: 20px;
  }
  
  .about-img-style-2 img {
    width: 400px;
    height: 400px;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
  
  /* Media Query for Mobile Screens */
  @media (max-width: 768px) {
    .history-area {
      height: auto; /* Adjust height to auto for better content wrapping */
      padding-top: 20px; /* Adjust padding */
    }
  
    .about-img-style-2 {
      width: 300px; /* Smaller width for mobile */
      height: 300px; /* Smaller height for mobile */
      padding: 10px; /* Adjust padding */
      margin: 0 auto; /* Center the image container */
      display: flex; /* Use flexbox to center the image */
      justify-content: center; /* Center horizontally */
      align-items: center;
      margin-bottom: 30px; /* Center vertically */
    }
  
    .about-img-style-2 img {
      width: 300px; /* Match image size to container */
      height: 300px; /* Match image size to container */
    }
  

  }
  

  /*about video block*/

  /* Modal Overlay */
.about-ola-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal Close Button */
.about-ola-modal-close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 30px;
    cursor: pointer;
}

/* Modal Video */
.about-ola-modal-video {
    width: 80%;
    max-width: 800px;
    border-radius: 10px;
}

/* About OLA Block */
.about-ola-block {
    padding-top: 10px;
}



/* About OLA Row */
.about-ola-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* About OLA Column */
.about-ola-col {
    flex: 1;
    padding: 15px;
}

/* About OLA Text */
.about-ola-text-wrapper {
    margin-right: 100px;
    margin-bottom: 40px;
}

/* About OLA Text */
.about-ola-text {
    padding-right: 15px;
}

/* Video Wrapper */
.about-ola-video-wrapper {
    position: relative;
    width: 420px;
    height: 420px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 100px;
}

/* About OLA Video */
.about-ola-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Play Button */
.about-ola-play-button {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

/* Play Icon */
.about-ola-play-icon {
    font-size: 24px;
}


/* Responsive adjustments for screens smaller than 768px */
@media (max-width: 768px) {
    .about-ola-text-wrapper {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .about-ola-text {
        padding-right: 0;
    }

    .about-ola-video-wrapper {
        width: 300px;
        height: 300px;
        border-radius: 50%;
        position: relative;
        margin: 0 auto; /* Center horizontally */
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -20%;
        left: 0;
    }

    .about-ola-video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .about-ola-play-button {
        width: 50px;
        height: 50px;
        font-size: 20px;
    }
    
    .about-ola-play-icon {
        font-size: 20px;
    }
}


/**
*   08. About
*/

.ola-title {
    font-weight: 300;
    color: #4c9152;
    font-size: 50px;
}

.ola-title-bold {
    font-weight: 600;
    color: #5391b7;
    font-size: 50px;
}

.ola-description {
    color: #5391b7;
    margin-top: 5%;
}

/* Responsive Design */
@media (max-width: 768px) {
    .about-area{
        height: fit-content;
        margin-bottom: -30%;
    }
    .ola-title,
    .ola-title-bold {
        font-size: 30px; /* Reduce font size on smaller screens */
    }

    .ola-description {
        margin-top: 20px; /* Adjust spacing */
        font-size: 16px; /* Adjust font size */
    }

    .about-img-style-1 {
        width: 280px !important; /* Adjust width on mobile */
        height: 280px !important;
        margin-left: 7% !important; /* Adjust height on mobile */
       
    }

    .about-img-style-1 img {
        width: 100%; /* Keep image width at 100% */
        height: 100%; /* Keep image height at 100% */
        border-radius: 50%;
         /* Keep the image circular */
    }

  
}



/* Your existing styles */

.about-img-style-1 {
    position: relative;
    z-index: 2;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    overflow: hidden;
}

.about-img-style-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}


.about-award {
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-35%, -50%);
    background: #c8b224;
    width: 220px;
    height: 220px;
    text-align: center;
    border-radius: 100%;
    opacity: 0.85;
}

.about-award h3 {
    font-size: 24px;
    color: #fff;
    padding: 70px 0;
    line-height: 34px;
}



.about-experience {
    position: absolute;
    left: -160px;
    bottom: 0;
}

.about-experience h2 {
    font-size: 46px;
}

.about-experience span {
    font-size: 18px;
    color: red;
}

.about-small-img {
    position: absolute;
    top: 20%;
    left: -160px;
    border-width: 12px 12px 12px 0;
    border-style: solid;
    border-color: #fff;
}

.about-author-avatar-wrapper img {
    border-radius: 100px;
    width: 70px;
    height: 70px;
    float: left;
    margin-right: 20px;
}

.about-author-avatar-content {
    float: left;
    overflow: hidden;
}

.about-author-avatar-content h5 {
    margin-bottom: 10px;
}

.about-author-avatar-content span {
    color: #cdb30c;
}

/*vision mission*/
.experience-area {
    position: relative;
    background-color: #d3f3f1;
}


.single-experience {
    padding: 25px;
    margin-bottom: 25px;
    border-radius: 70px;
    margin: 0px !important;
}

.single-experience h3 {
    font-size: 24px;
    background-color: #d3f3f1;
    color: #4c9152;
    display: inline-block;
    border-radius: 70px;
    margin-bottom: 2px;
}

.single-experience p {
    margin-bottom: 0;
    color: white;
}

.single-experience:hover {
    transition: 0.3s;
}

.single-experience:hover p {
    color: #fff;
}

.experience-small-block {
    position: absolute;
    left: 30px;
    bottom: 30px;
    background-color: #cdae20;
    width: 200px;
    height: 140px;
    padding: 20px;
}

.experience-small-block h5 {
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    margin-top: 5px;
    margin-bottom: 0;
}


@media (max-width: 768px) {

    .experience-area p {
        margin-top: -20%;
       
    }


}



.about-award {
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-35%, -50%);
    background: #c8b224;
    width: 220px;
    height: 220px;
    text-align: center;
    border-radius: 100%;
    opacity: 0.85;
}

.about-award h3 {
    font-size: 24px;
    color: #fff;
    padding: 70px 0;
    line-height: 34px;
}

.about-img-style-2 {
    position: relative;
    z-index: 2;
}

.about-experience {
    position: absolute;
    left: -160px;
    bottom: 0;
}

.about-experience h2 {
    font-size: 46px;
}

.about-experience span {
    font-size: 18px;
    color: red;
}

.about-small-img {
    position: absolute;
    top: 20%;
    left: -160px;
    border-width: 12px 12px 12px 0;
    border-style: solid;
    border-color: #fff;
}

.about-author-avatar-wrapper img {
    border-radius: 100px;
    width: 70px;
    height: 70px;
    float: left;
    margin-right: 20px;
}

.about-author-avatar-content {
    float: left;
    overflow: hidden;
}

.about-author-avatar-content h5 {
    margin-bottom: 10px;
}

.about-author-avatar-content span {
    color: #cdb30c;
}


.experience-area {
    position: relative;
    background-color: #d3f3f1;
}

.single-experience {
    padding: 25px;
    margin-bottom: 25px;
    border-radius: 70px;
}

.single-experience h4 {
    font-size: 24px;
    padding: 15px 30px;
    background-color: #d3f3f1;
    color: #5391b7;
    display: inline-block;
    margin-bottom: 16px;
    border-radius: 70px;
}

.single-experience p {
    margin-bottom: 0;
    color: white;
}

.single-experience:hover {
    transition: 0.3s;
}

.single-experience:hover p {
    color: #fff;
}

.experience-small-block {
    position: absolute;
    left: 30px;
    bottom: 30px;
    background-color: #cdae20;
    width: 200px;
    height: 140px;
    padding: 20px;
}

.experience-small-block h5 {
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    margin-top: 5px;
    margin-bottom: 0;
}

  .custom-accordion {
    border-radius: 16px !important; /* Override MUI styles */
    margin-top: 10px;
    background-color: #d3f3f1 !important;
  }
  

/**
*   09. Fun Fact
*/

.single-counter h2 {
    color: #fff;
    font-size: 55px;
    margin-bottom: 20px;
}

.single-counter p {
    color: #fff;
    font-size: 20px;
}

.fun-fact-area{
    background-color: #5391b7;
}


/**
*   10. Project
*/

.single-project {
    transition: .3s;
  
}

.project-area{
    background-color: #5391b7;
}
.single-project-img {
    height: 285px;
    position: relative;
    transition: .3s;
}

.single-project-img-icon {
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    opacity: 0;
    visibility: hidden;
}

.single-project:hover .single-project-img-icon {
    opacity: 1;
    visibility: visible;
}

.single-project-img-icon a svg {
    font-size: 14px;
    color: #523906;
    margin: 13px 0;
}

.single-project-content h3 {
    margin-bottom: 20px;
    color: #4c9152;
}

.single-project-content p {
    margin-bottom: 0px;
    color: #5391b7;
}

.single-project-content {
    background-color: #d3f3f1;
    padding: 50px 30px 35px 30px;

   
    
}

.single-project:hover .single-project-content {
    background-color: #cdb30c;
    transition: .3s;

}

.single-project:hover .single-project-content h3,
.single-project:hover .single-project-content p {
    color: #fff;
}

.single-project-icon {
    font-size: 3rem; /* Increase the size of the icon */
    margin-bottom: 20px; /* Adjust spacing as needed */
    color: #5391b7; /* Change color if needed */
}

/*Project Masonry Style*/

.portfolio-menu {
    display: inline-block;
    background-color: #cdb30c;
    padding: 0 40px;
    height: 72px;
}

.portfolio-menu button {
    border: none;
    background-color: transparent;
    color: #faf7e6;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Arial';
    padding: 20px 15px;
}

.portfolio-menu button {
    border-bottom: 5px solid #cdb30c;
}

.portfolio-menu button.active {
    border-bottom: 5px solid #523906;
}



.single-project-isotope {
    position: relative;
    overflow: hidden;
    height: 350px;
}

.single-project-isotope-style-2 {
    position: relative;
    overflow: hidden;
    height: 250px;
}

.single-project-isotope:hover:before,
.single-project-isotope-style-2:hover:before {
    height: 100%;
}

.single-project-isotope img,
.single-project-isotope-style-2 img {
    width: 100%;
    height: 350px;
}

.single-project-isotope:hover .project-isotope-content-wrapper {
    bottom: 12%;
    transform: translateY(-12%);
}

.project-isotope-content-wrapper {
    position: absolute;
    bottom: -190px;
    left: 0;
    z-index: 2;
    text-align: center;
    padding: 0 40px;
    transition: all 0.3s ease-out 0s;
}

.project-isotope-content-wrapper h3 {
    font-size: 24px;
    color: #fff;
}

.project-isotope-content-wrapper p {
    color: #fff;
}

.project-isotope-content-wrapper a {
    color: #cdb30c;
}

.project-isotope-content-wrapper a svg {
    margin-left: 7px;
}

.portfolio-heading-content-2 {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 2;
    text-align: center;
    padding: 0;
    transition: all 0.3s ease-out 0s;
    opacity: 0;
    visibility: hidden;
}

.single-project-isotope-style-2:hover .portfolio-heading-content-2 {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 2;
    text-align: center;
    padding: 0;
    transition: all 0.3s ease-out 0s;
    opacity: 1;
    visibility: visible;
}

.portfolio-heading-content-2 h3 {
    background-color: #cdb30c;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 15px;
    margin-bottom: 0;
}

.portfolio-content-2 {
    position: absolute;
    top: 40%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -40%);
}

.portfolio-content-2 a,
.portfolio-content-2 button {
    display: inline-block;
    background-color: #cdb30c;
    color: #fff;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50px;
    margin: 0 2px;
    opacity: 0;
    visibility: hidden;
    border: none;
}

.single-project-isotope-style-2:hover .portfolio-content-2 a,
.single-project-isotope-style-2:hover .portfolio-content-2 button {
    display: inline-block;
    background-color: #cdb30c;
    color: #fff;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50px;
    margin: 0 2px;
    opacity: 1;
    visibility: visible;
}


/**
*   11. Why Choose Us
*/

.why-choose-us-wrapper {
    height: 592px;
    background-color: var(--your-bg-color); /* Use a variable if defined */
    padding-top: 40px;
    position: relative;
    overflow: hidden;
  }
  
  .bg-image {
    background-image: 
    linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), 
    url('../../assets/images/why-choose-us/bg1.png');
    background-size: cover;
    background-position: center;
  }


  
  .section-heading {
    color: #4c9152;
    margin-bottom: 10px;
  }
  
  .icon-style {
    color: #4c9152;
    font-size: 1.5em;
  }
  
  .custom-style-0 {
    margin-right: 80px;
  }
  
  .custom-style-1 {
    margin-right: 80px;
  }
  
  .custom-style-2 {
    margin-right: 0; /* No margin for the last item */
  }
  
  .description-style-0 {
    color: white;
    font-size: 17px;
    margin-bottom: 20px;
  }
  
  .description-style-1 {
    color: white;
    font-size: 17px;
    margin-bottom: 70px;
  }
  
  .description-style-2 {
    color: white;
    font-size: 17px;
    margin-bottom: 40px;
  }
  
  /* Additional CSS */
  .single-choose-us {
    background-color: #4c9152;
    padding-top: 10%;
    box-shadow: 0px 3px 81px 0px rgba(6, 6, 6, 0.09);
    height: 370px;
    width: 370px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 20px;
    text-align: center;
  }
  
  .single-choose-us-icon {
    font-size: 35px;
    margin-bottom: 20px;
  }
  
  .choose-us-heading {
    text-align: center;
    font-size: 26px;
    margin-bottom: 15px;
    color: #4c9152;
  }
  
  .single-choose-us-content {
    padding: 0 15px;
    color: #ffffff;
    text-align: center;
  }
  
  .single-choose-us-content p {
    color: #ffffff;
    font-weight: 300;
    margin: 15px;
    font-size: 17px;
  }
  
  .read-more {
    color: #ffffff;
    font-size: 17px;
    line-height: 20px;
  }
  
  .read-more .fa-arrow-right {
    margin-left: 5px;
  }
  
  .read-more:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
    color: #ffffff;
  }
  
  /*point of collection*/


.point-of-collection-section {
    position: relative;
    overflow: hidden;
    background-color: #d3f3f1;
    padding-top: 40px;
    padding-bottom: 30px;
}

.point-of-collection-title {
    text-align: center;
}

.point-of-collection-heading-title {
    color: #4c9152;
}

.point-of-collection-description {
    margin-bottom: 30px; /* Adjust margin as needed */
}



.point-of-collection {
    background-color: #4c9152;
    padding-top: 5%;
    box-shadow: 0px 3px 81px 0px rgba(6, 6, 6, 0.09);
    height: 250px;  /* Adjusted height */
    width: 250px;   /* Adjusted width */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 20px;  /* Space between circles */
    text-align: center;
    padding-right: 10%;
    padding-left: 10%;
}

.point-of-collection-content p {
    font-size: 18px;
    color: white;
}

.point-of-collection-heading {
    text-align: center;
    font-size: 26px;
    color: #4c9152;
}

.collection-schedule-description {
    margin-top: 10px;
}

@media (max-width: 768px) {
    .point-of-collection-item-container {
        margin-bottom: 40px; /* Add margin-bottom for spacing */
    }

    .point-of-collection {
        height: 200px; /* Reduce height for smaller screens */
        width: 200px; /* Reduce width for smaller screens */
        padding-top: 10%;
        padding-left: 5%;
        padding-right: 5%;
    }

    .point-of-collection-content p {
        font-size: 16px; /* Smaller font size for better readability */
    }

    
    .point-of-collection-heading-title {
        color: #4c9152;
        font-size: 30px !important;
        font-weight: 300;
    }
    
    .point-of-collection-heading {
   
        font-size: 18px !important;
    }
    .point-of-collection-title p {
      
        font-size: 16px;
        font-weight: 300;
    }
    .point-of-collection-section p{
      
        font-size: 16px;
        text-align: center;
      
    }
}









/* Responsive Styles for Mobile Devices */
@media (max-width: 768px) {
    /* Adjust container circle size */
    .single-choose-us {
      height: 320px;  /* Adjusted height for smaller screens */
      width: 320px;   /* Adjusted width for smaller screens */
      margin: 15px;   /* Reduced margin */
    }
  
    .section-heading{
        font-size: 30px !important;
        font-weight: 300 !important;
    }
    /* Adjust the icon size */
    .single-choose-us-icon {
      font-size: 30px; /* Smaller icon size */
      margin-bottom: 15px;
    }
  
    /* Adjust heading size */
    .choose-us-heading {
      font-size: 22px; /* Smaller font size for headings */
      margin-bottom: 10px;
    }
  
    /* Adjust content padding and font size */
    .single-choose-us-content p {
      font-size: 15px; /* Smaller font size for descriptions */
      margin: 10px;
    }
  
    /* Adjust the Read More link size */
    .read-more {
      font-size: 15px; /* Smaller font size for links */
    }
  
    /* Adjust collection circle size */
    .point-of-collection {
      height: 200px;  /* Adjusted height for smaller screens */
      width: 200px;   /* Adjusted width for smaller screens */
    }
  
    /* Adjust collection heading size */
    .point-of-collection-heading {
      font-size: 22px; /* Smaller font size for headings */
    }

    .why-choose-us-wrapper{
        height: 80% !important;
    }
    .custom-style-0 {
        margin-right: 0;
      }
      
      .custom-style-1 {
        margin-right: 0;
      }
      
      .custom-style-2 {
        margin-right: 0; /* No margin for the last item */
      }
  }


/**
*   12. Testimonial
*/

.single-testimonial {
    background-color: #fff;
    padding: 36px 56px;
    margin-bottom: 20px;
    text-align: center;
}

.single-testimonial img {
    width: 110px;
    border-radius: 100%;
    margin-bottom: 30px!important;
    margin: 0 auto;
}

.single-testimonial h3 {
    font-size: 20px;
    margin-bottom: 16px;
}

.single-testimonial span {
    display: block;
    color: #cdb30c;
    margin-bottom: 25px;
    font-size: 18px;
}

.single-testimonial p {
    margin-bottom: 18px;
}

.star-rating svg {
    color: #cdb30c;
    margin: 0 2px;
    font-size: 18px;
}

.testimonial-style-1 ul.slick-dots {
    text-align: center;
    margin-top: 50px;
}

.testimonial-style-1 ul.slick-dots button {
    width: 27px;
}

.testimonial-style-1 ul.slick-dots li button {
    background-color: #523906;
    border: none;
    width: 23px;
    height: 7px;
    border-radius: 50px;
}

.testimonial-style-1 ul.slick-dots li {
    list-style: none;
    display: inline-block;
    margin-right: 10px;
    font-size: 0;
}

.testimonial-style-1 ul.slick-dots li.slick-active button {
    width: 36px;
    height: 7px;
    background-color: #ccb20c;
}

.testimonial-style-1 .slick-slide {
    margin: 0 10px;
    width: 412px!important;
}

.testimonial-img img {
    border-radius: 10px 350px 350px 350px;
    border-width: 10px;
    border-style: solid;
    border-color: #cdb30c;
}

.testimonial-style-2-wrapper {
    position: relative;
}

.testimonial-style-2 {
    background-color: #fff;
    margin-left: -240px;
    margin-bottom: 70px;
    padding: 60px 100px 60px 100px;
    border-radius: 150px 150px 10px 150px;
    box-shadow: 0px 3px 81px 0px rgb(0 0 0 / 9%);
    position: relative;
}

.single-testimonial-style-2 p {
    color: #523906;
    font-size: 18px;
    margin-bottom: 30px;
}

.single-testimonial-style-2 h4 {
    font-size: 20px;
    margin-bottom: 15px;
}

.single-testimonial-style-2 span {
    font-size: 18px;
    color: #cdb30c;
}

.testimonial-style-2 ul.slick-dots {
    position: absolute;
    bottom: 67px;
    right: 100px;
}

.testimonial-style-2 ul.slick-dots li {
    display: inline-block;
    margin-left: 10px;
}

.testimonial-style-2 ul.slick-dots li button {
    font-size: 0;
    background-color: #f0e8b6;
    border: none;
    width: 10px;
    height: 10px;
    border-radius: 100%;
}

.testimonial-style-2 ul.slick-dots li.slick-active button {
    background-color: #cdb30c;
}

.testimonial-quote-icon {
    background-color: #cdb30c;
    position: absolute;
    top: -140px;
    left: -110px;
    width: 85px;
    height: 170px;
    text-align: center;
    border-radius: 50px 50px 50px 0px;
}

.testimonial-quote-icon svg {
    font-size: 36px;
    color: #fff;
    line-height: 170px;
    margin: 67px 0;
}


/**
*   13. Book Appointment
*/

/* Ensure the container takes up the full height of the viewport */
.interactive-map-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Add a top margin to move content upwards */
    padding-top: 2rem; /* Adjust this value as needed */
}

/* Center the content within the book-appointment */
.book-appointment {
    width: 100%;
    position: relative; 
    /* Ensure that any absolute positioning is relative to this container */
}
.circular-map {
    border-radius: 70px; /* Makes the map circular */
    overflow: hidden; /* Ensures the content does not overflow the circular border */
 
}


/* Ensure map and text columns take full width on small screens */
.map-col, .text-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Ensure the map container takes full width */
.map-col {
    padding: 15px;
    /* Adjust margin to move map and content upwards */
    margin-top: -20px; /* Adjust this value as needed */
    margin-left: -65px; /* Adjust this value as needed */
}


/* Responsive adjustments */
@media (max-width: 768px) {
    .section-title {
        margin-bottom: 90px;
    }
    /* Ensure the map container takes full width */
.map-col {
  
    margin-left: 0; 
    margin-top: -15%;
}
.section-title h2 {
    font-size: 30px !important;
    font-weight: 300 !important;

    
}


.section-title span,p {
    font-size: 16px !important;
  
    
}
.interactive-map-container {
    display: flex;
    flex-direction: column; /* Default to column layout for mobile */
    gap: 20px; /* Spacing between elements */
}
    
    .map-info-container {
        max-width: 300px; /* Limit width of the text area */
        margin-right: 20px; /* Add spacing between text and map */
    }


}


.book-appointment-img {
    position: absolute;
    left: 0;
    width: 50%;
    height: 100%;
}

.book-quote-widget input[type="text"],
.book-quote-widget input[type="email"] {
    margin-right: 20px;
}

.book-quote-widget input[type="text"],
.book-quote-widget input[type="email"],
.book-quote-widget textarea {
    background-color: #f1f1f1;
    border: none;
    padding: 20px 18px;
    width: 100%;
    margin-bottom: 18px;
}

a.l-btn.quote-btn {
    width: 100%;
    border-radius: 0;
    padding: 20px 17px;
}

a.l-btn.quote-btn svg {
    float: right;
}






/**
*   14. How It Works
*/
.works-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 20px;
}

/* Align the images and text */
.works-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.works-icon img {
    border-radius: 50%;
    border: 4px solid #4c9152;
 
    width: 400px;
    height: 400px;
    object-fit: cover;
}

/* Adjustments for the menu */
.works-menu .nav-item {
    display: inline-block;
    margin: 0 40px; /* Adjust the space between menu items */
    text-align: center;
}

.works-menu .nav-pills .nav-link {
    padding: 0;
}

.works-menu .nav-pills .nav-link.active {
    background-color: transparent;
}

.works-menu a {
    border: none;
    background-color: transparent;
}

/* Ensure h3 text is aligned and spaced */
.works-content-wrapper h3 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px; /* Adjust spacing between the title and the content */
}

/* Adjust works-img styling */
.works-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px; /* Spacing between image and text */
    max-width: 500px;
    margin-right: auto;
    margin-left: auto; /* Centers the content horizontally */
}


/* Flex container to add space between columns */
.row-milestone {
    display: flex;
    justify-content: space-around; /* or 'space-evenly' for a smaller gap */
    margin: 0 -10px; /* Reduce the negative margin to decrease space */
    padding: 0 10px; /* Adjust padding to control spacing within the container */
}
/* Adjust the first column */
.milestone-first {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%; /* Adjust width as needed */
}
/* Adjust the second column */
.milestone-second {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%; /* Adjust width as needed */
}

@media (max-width: 768px) {
    .works-content-wrapper {
        padding: 0 10px;
    }

    .works-icon img {
        width: 200px;
        height: 200px;

    }

    .works-menu .nav-item {
        margin: 0 10px; /* Adjust spacing for mobile */
    }

    .works-content-wrapper h3 {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 15px; /* Adjust spacing for mobile */
    }

    .works-text {
        max-width: 100%; /* Full width on mobile */
        margin-bottom: 15px;
    }

    .row-milestone {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items */
        margin: 0; /* Remove margin */
        padding: 0; /* Remove padding */
    }

    .milestone-first, .milestone-second {
        width: 100%; /* Full width on mobile */
        margin-bottom: 20px; /* Add space between stacked items */
    }
    .how-it-works h2{
        margin-bottom: -20%;
    }
    .works-content-wrapper h3{
        font-size: 18px !important;
    }
}



/*success stories*/
.full-width-video {
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    margin-bottom: 30px;
}

.video-style {
    width: 100%;
    height: 70vh;
    object-fit: cover;
}

.blog-details-content {
    position: relative;
    right: 25%;
}

.blog-title {
    margin-bottom: 20px;
}

@media (max-width: 768px) { /* Tablet and smaller screens */
    .blog-details-content {
        right: 0px; /* Align content centrally */
        padding: 0 10px; /* Less padding for smaller screens */
    }

    .video-style {
        height: 50vh;
        width: fit-content; /* Reduce video height for smaller screens */
    }
    .blog-title {
       font-size: 30px;
       font-weight: 300;
    }
    
}







/**
*   15. Blog
*/

.single-blog-wrapper {
    background-color: #fff;
    transition: .3s;
}

.blog-details img,
.blog-img img {
    width: 100%;
}

.blog-img a {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.single-blog-wrapper:hover .blog-img a {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible;
    transition: .3s;
}

.blog-content h4 a {
    display: block;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 30px;
}

.blog-content h2 a {
    display: block;
    margin-bottom: 40px;
}

ul.cat-list li {
    display: inline-block;
    margin-right: 17px;
}

ul.cat-list li a {
    color: #523906;
}

ul.cat-list li.parent-cat {
    position: relative;
    padding-left: 20px;
}

ul.cat-list li.parent-cat a {
    color: #cdb30c;
}

ul.cat-list li.parent-cat:before {
    content: '';
    display: block;
    width: 4px;
    height: 20px;
    border-radius: 50px;
    background-color: #cdb30c;
    position: absolute;
    top: 0;
    left: 0;
}

ul.post-meta li {
    display: inline-block;
}

ul.post-meta li a {
    color: #686868;
}

ul.post-meta li:after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50px;
    background-color: #523906;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-right: 7px;
}

ul.post-meta li:last-child:after {
    display: none;
}

ul.post-meta li {
    position: relative;
    padding-right: 22px;
}

a.l-read-more {
    color: #cdb30c;
}

a.l-read-more svg {
    margin-left: 5px;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.single-blog-wrapper:hover a.l-read-more svg {
    opacity: 1;
    visibility: visible;
    transition: .3s;
}

.landi-pagination ul li {
    display: inline-block;
}

.landi-pagination ul li {
    margin: 0 3px;
}

.landi-pagination ul li a {
    display: block;
    background-color: #cdb30c;
    color: #fff;
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Arial';
}

.landi-pagination ul li a:hover {
    background-color: #523906;
    color: #fff;
}

.landi-pagination ul li span.current {
    display: block;
    background-color: #523906;
    color: #fff;
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Arial';
}

/**
*   16. Footer
*/

.footer-top {
    padding: 40px 0;
    background-size: cover;
    background-position: center;
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    position: relative;
    left: -170px; /* Move content to the left */
}

.footer-title h4 {
    font-size: 24px; /* Increase from 20px to 24px */
    margin-bottom: 20px;
    color: white;
}

.footer-info a, .footer-menu ul li a {
    color: white;
    font-size: 18px; /* Increase from 16px to 18px */
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
    transition: color 0.3s ease;
}




.footer-logo {
    margin-top: 20px;
    max-width: 300px; /* Increase logo size from previous width */
    height: auto; /* Maintain proportions */
    margin-left: 50%;
}

.footer-menu ul {
    list-style: none;
    padding-left: 0;
}

.footer-menu ul li {
    margin-bottom: 10px;
    font-size: 16px;
}

.footer-menu ul li a {
    color: white;
}

.footer-menu ul li a:hover {
    text-decoration: underline;
}

.footer-info a svg, .footer-info a {
    vertical-align: middle;
    margin-right: 10px;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.col-xl-4, .col-xl-2, .col-lg-4, .col-lg-2, .col-md-6, .col-sm-12 {
    padding: 15px;
}
.quick-links {
    margin-top: -6%; 
    margin-left: -3%;
}
.sectors-logo-wrapper {
    margin-top: -6%; /* Adjust this value to control how much the column moves up */
}

/* Flexbox for larger screens */
@media (min-width: 992px) {
    .footer-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .col-xl-4, .col-lg-4 {
        width: 33.33%;
    }
    .col-xl-2, .col-lg-2 {
        width: 16.66%;
    }
}

/* For medium screens */
@media (max-width: 991px) and (min-width: 768px) {
    .footer-container {
        flex-direction: row;
    }
    .col-md-6 {
        width: 50%;
    }
}
/* For smaller screens */
@media (max-width: 768px) {
    .footer-container {
        display: flex;
        flex-wrap: wrap; /* Allows wrapping of columns */
        left:10px;
    }

    .col-sm-12 {
        width: 50%; /* Two columns per row on mobile */
        box-sizing: border-box; /* Ensure padding is included in width */
    }

    .footer-logo {
        margin: 20px auto;
        width: 150px !important;
    }
    .footer-title h4 {
        font-size: 18px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
    .footer-info a, .footer-menu ul li a {
        font-size: 14px;
    }
    .col-sm-12 {
        width: 100%;
        padding: 10px;
    }
    .footer-col{
        width: 50%;
    }

    .quick-links{
        margin-top: -42%;
    }

    .sectors-logo-wrapper{
        margin-top: 5%;
    }
}



/**
*   17. Breadcrumb
*/

.breadcrumb-content-wrapper h2 {
    color: #fff;
    font-size: 46px;
    line-height: 60px;
    margin-bottom: 28px;
}

.breadcrumb-content-wrapper p {
    color: #fff;
    font-size: 20px;
}

.breadcrumb-content-wrapper ul.post-meta li:after {
    background-color: #5391b7;
}

.breadcrumb-content-wrapper ul li,
.breadcrumb-content-wrapper ul li a {
    color: #5391b7;
}

.breadcrumb-navigation {
    text-align: center;
}

.breadcrumb-navigation ul li {
    font-family: 'Arial';
    font-size: 20px;
    font-weight: 700;
    color: #fff;
}

.breadcrumb-navigation ul li {
    display: inline-block;
    color: #fff;
}

.breadcrumb-navigation ul li:hover a {
    color: #5391b7;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
    }

.breadcrumb-navigation ul li.active {
    color: #5391b7;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: '-';
    color: #fff;
    padding: 0 10px;
}

@media (max-width: 768px) {
.breadcrumb-content-wrapper h2 {
    font-size: 30px;

}
.breadcrumb-navigation ul li {
    font-size: 18px;
 
}
}

/**
*   18. CTA
*/

.cta-style-1-wrapper span {
    display: block;
    font-size: 18px;
    color: #cdb30c;
    margin-bottom: 25px;
}

.cta-style-1-wrapper h2 {
    font-size: 36px;
    color: #fff;
    margin-bottom: 30px;
    line-height: 46px;
}

.simple-cta h3 {
    font-size: 24px;
    margin-bottom: 0;
}

.cta-style-1-wrapper p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 38px;
}

.cta-style-1-wrapper .l-btn:hover {
    background-color: #cdb30c;
}

.cta-style-2-wrapper h2 {
    color: #fff;
    font-size: 30px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 0;
}

.cta-style-2-wrapper h2 span a {
    color: #fff;
}

.cta-style-2-wrapper h2 span {
    font-weight: 700;
}

.cta-style-3 {
    margin-top: -100px;
}

.cta-style-3-wrapper {
    position: relative;
    z-index: 1;
}

.cta-style-3-wrapper:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 12px;
    left: 12px;
    border: 2px solid #e5d77e;
    z-index: -1;
}

.cta-style-3-wrapper h3 {
    line-height: 38px;
    margin-bottom: 0;
}

.cta-shape {
    position: absolute;
    bottom: 30%;
    right: 32%;
    transform: rotate(330deg);
}


/**
*   19. Widgets
*/

.widget {
    padding: 35px 20px;
    position: relative;
    background-color: #f1f1f1;
}

.widget:before {
    content: '';
    display: block;
    width: 320px;
    height: 4px;
    background-color: #cdb30c;
    position: absolute;
    top: 0;
    left: 0;
}

.widget.contact-widget:before {
    display: none;
}

.widget-title {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 30px;
}

.contact-widget h3 {
    color: #fff;
}

.service-list-widget ul li a {
    display: block;
    color: #523906;
    background-color: #fff;
    padding: 18px;
    margin: 11px 0;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Arial';
    line-height: 30px;
}

.service-list-widget ul li a svg {
    float: right;
    margin: 7px 0;
}

.service-list-widget ul li a:hover {
    color: #fff;
    background-color: #cdb30c;
}

.contact-widget {
    background-color: #523906;
    padding: 40px 40px 30px 30px;
}

.contact-widget span {
    display: block;
    font-size: 20px;
    color: #fff;
    margin-bottom: 25px;
}

.contact-widget p {
    color: #fff;
    margin-bottom: 35px;
}

.contact-widget h3 a {
    display: inline-block;
    color: #fff;
    margin-top: 35px;
}

a.l-contact-btn {
    display: inline-block;
    background-color: #cdb30c;
    color: #fff;
    padding-left: 80px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 700;
    position: relative;
    width: 209px;
    height: 60px;
    line-height: 60px;
}

a.l-contact-btn span {
    background-color: #fff;
    border-radius: 50px;
    position: absolute;
    left: 0;
    top: 0;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}

a.l-contact-btn span svg {
    color: #523906;
}

.quote-widget input {
    width: 100%;
    height: 60px;
    padding: 0 22px;
    font-size: 16px;
    border: none;
    margin: 7px 0;
    background-color: #fff;
}

.quote-widget textarea {
    width: 100%;
    border: none;
    padding: 15px 22px;
    font-size: 16px;
    margin: 7px 0;
    background-color: #fff;
}

.quote-widget button {
    background-color: #cdb30c;
    color: #fff;
    padding: 15px 20px;
    display: block;
    border: none;
    width: 100%;
    text-align: left;
    line-height: 30px;
}

.quote-widget button:hover {
    background-color: #523906;
}

.quote-widget svg {
    float: right;
    margin: 7px;
}

.search-widget input {
    height: 66px;
    width: 100%;
    border: 2px solid #cdb30c;
    padding: 0 20px;
    color: #523906;
}

.search-widget button {
    position: absolute;
    right: 20px;
    width: 66px;
    height: 66px;
    line-height: 66px;
    border: none;
    background-color: #cdb30c;
    color: #fff;
    font-size: 20px;
    text-align: center;
}

.category-widget ul li {
    display: block;
    margin-bottom: 25px;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Arial';
    color: #523906;
}

.category-widget ul li:last-child {
    margin-bottom: 0;
}

.category-widget ul li a {
    display: inline-block;
}

.category-widget ul li:hover,
.category-widget ul li:hover a {
    color: #cdb30c;
}

.category-widget ul li span {
    float: right;
}

.single-latest-post-widget {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #c9c3b6;
}

.single-latest-post-widget:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: none;
}

.single-latest-post-widget h5 a {
    display: block;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 25px;
}

.single-latest-post-widget span {
    display: block;
    color: #cdb30c;
}

.single-latest-post-widget span:last-child {
    border: none;
}

.tag-cloud-widget .l-btn {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Arial';
    padding: 13px 20px;
    margin-right: 5px;
    margin-bottom: 10px;
    text-transform: capitalize;
    background-color: #fff;
    color: #523906;
}

.tag-cloud-widget .l-btn:hover {
    background-color: #cdb30c;
    color: #fff;
}


/**
*   20. FAQ
*/

.faq .accordion {
    border: none;
}

.faq .accordion__button:before {
    float: right;
    margin: 10px 0;
}

.faq .accordion__button {
    color: #523906;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Arial';
    background-color: transparent;
    outline: none;
    box-shadow: none;
}

.faq .accordion__button:hover {
    background-color: none;
}

.faq .accordion__panel {
    border-top: 1px solid #e5e1da;
}

.faq .accordion-button:not(.collapsed)::after {
    transform: var(--bs-accordion-btn-icon-transform);
}

.faq h2 {
    margin-bottom: 0;
}

.accordion__item {
    border: none;
    background-color: #fff;
    color: #686868;
    margin-bottom: 10px;
    padding: 0 20px;
    box-shadow: 0px 3px 81px 0px rgb(6 6 6 / 9%);
}

.faq .accordion-button:focus {
    outline: none;
}


/**
*   21. Service/Project Details
*/

.service-details-wrapper h2 {
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 33px;
}

.accordion-item h2 {
    margin-bottom: 0;
    line-height: 30px;
}

.service-details-wrapper img,
.project-details-wrapper img {
    width: 100%;
}

.single-project-info {
    padding: 35px;
    text-align: center;
}

.single-project-info h6 {
    color: #fff;
}

.single-project-info span {
    display: block;
    color: #fff;
    margin-bottom: 15px;
}


/**
*   22. Pricing
*/

.single-pricing-box h3 {
    color: #fff;
    font-size: 46px;
    line-height: 70px;
}

.single-pricing-box h4 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 25px;
}

.single-pricing-box a {
    color: #cdb30c;
}

.single-pricing-box a svg {
    margin-left: 7px;
}

.single-pricing-box ul li {
    margin: 16px 0;
}

.single-pricing-box ul li svg {
    color: #cdb30c;
    margin-right: 10px;
}


/**
*   23. Error 404
*/
.error-404-wrapper {
    text-align: center;
    margin-top: 15%;
}

.error-404-wrapper iframe {
    width: 500px;
    height: 500px;
    margin-top: -10%;
   
}

.error-404-wrapper h3 {
    margin-top: -8%;
    font-size: 40px;
    margin-bottom: 2%;
}
.error-404-wrapper p {
    margin-bottom: 5%;
    
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
    .error-404-wrapper iframe {
        width: 90%; /* Adjust width for smaller screens */
        height: fit-content; 
            /* Adjust height for aspect ratio */
    }

    .error-404-wrapper h3 {
        font-size: 1.75rem; 
        margin-top: 2%;
    }

    .error-404-wrapper p {
        font-size: 0.875rem;
    }
    .error-404-wrapper {
        text-align: center;
        margin-top: 80% !important;
    }
}



/**
*   24. Blog Details
*/

.quote-block {
    margin-top: 50px;
    margin-bottom: 80px;
}

.quote-icon {
    width: 48px;
    height: 48px;
    background-color: #fff;
    color: #cdb30c;
    border-radius: 50px;
    text-align: center;
    font-size: 20px;
    line-height: 48px;
    display: inline-block;
    margin-bottom: 40px;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.quote-block p {
    font-size: 20px;
    font-weight: 600;
    font-family: 'Arial';
    line-height: 30px;
}

.post-tags h5,
.post-share h5 {
    margin-bottom: 16px;
}

.post-tags ul li {
    display: inline-block;
}

.post-tags ul li a {
    display: inline-block;
    background-color: #f1f1f1;
    color: #523906;
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 5px;
    margin-bottom: 8px;
}

.post-share ul li {
    display: inline-block;
    width: 35px;
    height: 35px;
    background-color: #f1f1f1;
    line-height: 35px;
    text-align: center;
    border-radius: 50px;
    font-size: 14px;
}

.post-share ul li a {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: #cdb30c;
    color: #fff;
    border-radius: 50px;
    line-height: 33px;
}

.post-tags ul li a:hover,
.post-share ul li a:hover {
    background-color: #523906;
    color: #fff;
}

.post-prev-btn:hover,
.post-next-btn:hover {
    background-color: #cdb30c;
    color: #fff;
    padding: 22px 36px;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Arial';
    border-radius: 50px;
    text-transform: uppercase;
}

.post-prev-btn,
.post-next-btn {
    color: #523906;
    padding: 22px 36px;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Arial';
    border-radius: 50px;
    text-transform: uppercase;
}

.post-prev-btn i {
    margin-right: 10px;
}

.post-next-btn i {
    margin-left: 10px;
}

.post-author-avatar {
    border-left: 3px solid #cdb30c;
}

.post-author-avatar img {
    border-radius: 5px;
}

.post-author-bio h5 a {
    font-size: 20px;
}

.post-author-bio h5 a:hover,
.post-author-bio a:hover {
    color: #cdb30c;
}

.post-author-bio a {
    color: #523906;
    font-size: 14px;
    margin-right: 10px;
}

.comment-body {
    background-color: #fff;
    padding: 27px;
    margin-bottom: 35px;
    box-shadow: 0px 3px 81px 0px rgb(0 0 0 / 9%);
}

.comments-area h2,
.comments-form h2 {
    font-size: 30px;
    margin-bottom: 35px;
}

.comment-avatar {
    float: left;
    margin-right: 30px;
}

.comment-content {
    overflow: hidden;
}

.comment-content h5 {
    display: inline-block;
}

.comment-content h5 a {
    font-size: 20px;
}

.comment-metadata {
    display: inline-block;
    margin-left: 10px;
}

.comment-metadata span,
.comment-metadata a {
    color: #cdb30c;
}

.comment-reply a {
    font-size: 16px;
    font-weight: 700;
    font-family: 'Arial';
    text-transform: uppercase;
    color: #cdb30c;
}

.comments-area li ul.children {
    padding-left: 40px;
}

.comments-form input {
    width: 100%;
    height: 60px;
    padding: 0 20px;
    color: #523906;
    border: 1px solid #f1efeb;
    margin-bottom: 20px;
    border-radius: 5px;
}

.comments-form textarea {
    width: 100%;
    height: 120px;
    padding: 20px;
    color: #523906;
    border: 1px solid #f1efeb;
    border-radius: 5px;
    margin-bottom: 20px;
}

.comments-form input:focus,
.comments-form textarea:focus {
    border: 1px solid #cdb30c;
}

.comments-form button {
    width: 270px;
    height: 60px;
    border-radius: 5px;
    display: inline-block;
}

.comments-form button svg {
    margin-left: 10px;
}


/**
*   25. Newsletter
*/

.newsletter-content-wrapper h2 {
    font-size: 36px;
    line-height: 46px;
    color: #fff;
    margin-bottom: 60px;
}

.newsletter-content-wrapper input {
    width: 50%;
    height: 60px;
    padding: 0 30px;
    border-radius: 50px;
    border: none;
    margin-right: 20px;
}


/**
*   26. Features
*/

.single-feature {
    padding: 47px 25px;
    transition: all 0.3s ease-out 0s;
}

.single-feature img {
    margin-bottom: 25px;
}

.single-feature h5 {
    margin-bottom: 25px;
}

.single-feature:hover {
    background-color: #523906;
}

.single-feature:hover h5,
.single-feature:hover p {
    color: #fff;
}


/**
*   27. Team
*/
.team-linkedin{

    color: #5391b7;
    
  
   

}
.team-linkedin:hover{

    color: #5391b7;
    
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
   

}
.team-area {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh; /* Takes up full viewport height */
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.single-team-style-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    
}

.single-team-style-1-img {
    position: relative;
}

.single-team-style-1-img img {
    border-radius: 50%;
    width: 400px;
    height: 400px;
}

.team-social-style-1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    opacity: 0;
    width: 100%;
}

.team-social-style-1 a {
    display: inline-block;
    color: #fff;
    width: 36px;
    height: 36px;
    border: 3px solid #4c9152;
    text-align: center;
    line-height: 30px;
    border-radius: 50px;
    font-size: 16px;
    margin: 0 2px;
}

.single-team-style-1:hover .team-social-style-1 {
    visibility: visible;
    opacity: 1;


}



.single-team-style-1 h4 {
    margin-top: 35px;
    font-size: 20px;
}

.single-team-style-1 span {
    font-size: 18px;
    color: #5391b7;
}

.team-border-bottom {
    position: relative;
}


@media (max-width: 768px) {
    .single-team-style-1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 20px;
        
    }
    
    .single-team-style-1-img {
        position: relative;
    }
    
    .single-team-style-1-img img {
        border-radius: 50%;
        width: 300px;
        height: 300px;
    }
    
    .single-team-style-1 h4 {
        margin-top: 35px;
        font-size: 18px;
    }

    .single-team-style-1 span {
        font-size: 16px;
        color: #5391b7;
    }
    .team-area {
        margin-top: 10%;
    }
}


/**
*   28. Quote Form
*/

.quote-title h2:after {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    background-color: #cdb30c;
    margin-top: 25px;
}

.quote-title h2 {
    color: #fff;
    margin-bottom: 30px;
}

.quote-title p {
    color: #fff;
}

.book-quote-form,
.quote-area-form {
    background-color: #fff;
    margin-left: -40%;

}

.quote-area-form h3 {
    font-size: 24px;
    margin-bottom: 40px;
}

.book-quote-form input,
.quote-area-form input,
.contact-form input {
    width: 100%;
    height: 60px;
    padding: 0 20px;
    border: none;
    background-color: #f1f1f1;
    margin-bottom: 20px;
}

.book-quote-form textarea,
.quote-area-form textarea,
.contact-form textarea {
    width: 100%;
    height: 178px;
    background-color: #f1f1f1;
    border: none;
    padding: 20px;
    margin-bottom: 15px;
}

.book-quote-form button,
.quote-area-form button,
.contact-form button {
    width: 100%;
    border-radius: 0;
    font-weight: 400;
}


/**
*   29. Contact
*/

.contact-form .section-title span {
    margin-bottom: 20px;
}

.contact-form .l-btn {
    text-align: left;
}

.contact-form .l-btn svg {
    float: right;
}

.contact-form input,
.contact-form textarea {
    background-color: transparent;
    border: 1px solid #edebe6;
}

.contact-box span {
    display: block;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 30px;
}

.contact-box h3 {
    font-size: 30px;
    color: #fff;
    margin-bottom: 25px;
}

.contact-box p {
    color: #fff;
}

.contact-box ul li svg {
    float: left;
    color: #cdb30c;
    line-height: 26px;
    margin: 5px 15px 5px 0;
}

.contact-box ul li span {
    display: block;
    overflow: hidden;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Arial';
    line-height: 26px;
    color: #fff;
    margin-bottom: 35px;
}

.contact-box ul li span a {
    color: #fff;
}

.social-btn-area .l-btn {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Arial';
    text-transform: capitalize;
    padding: 0;
    width: 200px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}

.contact-testimonial h2 {
    font-size: 30px;
    margin-bottom: 25px;
}

.single-testimonial-style-3-navigator img {
    width: 65px;
    height: 65px;
    border-radius: 50px;
    float: left;
    margin-right: 20px;
    padding: 3px;
}

.single-testimonial-style-3-navigator h5 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.single-testimonial-style-3-navigator {
    opacity: .4;
}

.slick-active.slick-current .single-testimonial-style-3-navigator {
    opacity: 1;
}

.slick-active.slick-current .single-testimonial-style-3-navigator img {
    width: 65px;
    height: 65px;
    border: 1px solid #cdb30c;
    padding: 3px;
}

.testimonial-style-3-navigator .slick-prev,
.testimonial-style-3-navigator .slick-next {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50px;
    font-size: 14px;
    background-color: #523906;
    color: #fff;
    position: absolute;
    bottom: 15px;
    z-index: 99;
}

.testimonial-style-3-navigator .slick-prev {
    right: 40px;
}

.testimonial-style-3-navigator .slick-next {
    right: 0;
}

.testimonial-style-3-navigator .slick-arrow:hover {
    background-color: #cdb30c;
    color: #fff;
}

.map-area iframe {
    width: 100%;
    height: 300px;
    margin-bottom: 0;
}